import React from "react";
import "./loader.scss";

const Loader = (props) => {
  return (
    <div style={{ display: props?.loader ? "" : "none" }}>
      <div className="overlay"></div>
      <div className="absolute text-center">
        <div className="lds-spinner">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default Loader;
