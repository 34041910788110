export const chargeStyles = () => ({
  main_div: { padding: "2rem" },
  main_div_768: { marginTop: "0rem" },
  textfield: {
    height: "20px",
    fontSize: "13px !important",
    padding: "8px",
    border: "1px solid #DCDCDC",
    borderRadius: "4px",
    background: "#FFFFFF",
    "&[disabled]": {
      pointerEvents: "none",
      background: "#F5F5F5",
    },
    width: "95%",
  },
  textfieldexp: {
    height: "20px",
    fontSize: "13px !important",
    padding: "8px",
    border: "1px solid #DCDCDC",
    borderRadius: "4px",
    background: "#FFFFFF",
    "&[disabled]": {
      pointerEvents: "none",
      background: "#F5F5F5",
    },
    width: "86%",
  },
  maintenanceImg: { width: "100%" },
  bottomText: {
    marginTop: "30px",
    textAlign: "center",
    justifyContent: "center",
  },
  subText: {
    fontSize: "18px",
    marginTop: "20px",
  },
  maintenanceImg_768: {
    width: "100%",
  },
  app_error_main_div_768: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "13rem",
  },
  app_error_main_div: {
    display: "flex",
    flexDirection: "row-reverse",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "13rem",
    gap: "2rem",
  },
  sub_div: {
    background: "#FFF",
  },
});

export const chargeSessionStyles = () => ({
  startButton: {
    padding: "25px 50px",
    height: "50px",
    display: "flex",
    borderRadius: "10px",
    border: "none",
    alignItems: "center",
    letterSpacing: "0.00938em",
    background: "#008000",
    color: "#fff",
    fontSize: "23px",
    fontWeight: "600",
    opacity: "85%",
    justifyContent: "center",
    boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 10px",
    cursor: "pointer",
  },
  stopButton: {
    padding: "25px 50px",
    height: "50px",
    display: "flex",
    borderRadius: "10px",
    border: "none",
    alignItems: "center",
    letterSpacing: "0.00938em",
    background: "#e8000d",
    color: "#fff",
    fontSize: "23px",
    fontWeight: "600",
    opacity: "85%",
    justifyContent: "center",
    boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 10px",
    cursor: "pointer",
  },
  startButton_768: {
    padding: "28px 60px",
    height: "50px",
    display: "flex",
    borderRadius: "10px",
    border: "none",
    alignItems: "center",
    letterSpacing: "0.00938em",
    background: "#008000",
    color: "#fff",
    fontSize: "27px",
    fontWeight: "600",
    opacity: "85%",
    justifyContent: "center",

    boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 10px",
  },
  stopButton_768: {
    padding: "28px 60px",
    height: "50px",
    display: "flex",
    borderRadius: "10px",
    border: "none",
    alignItems: "center",
    letterSpacing: "0.00938em",
    background: "#e8000d",
    color: "#fff",
    fontSize: "27px",
    fontWeight: "600",
    opacity: "85%",

    justifyContent: "center",
    boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 10px",
  },
  textArea: {
    width: "300px",
    borderRadius: "4px",
    height: "80px",
    fontSize: "14px",
    fontFamily: "Montserrat",
    padding: "7px",
    border: "1px solid",
  },
  saveButton: {
    padding: "8px 35px",
    height: "30px",
    display: "flex",
    borderRadius: "4px",
    border: "none",
    alignItems: "center",
    letterSpacing: "0.00938em",
    background: "#008000",
    color: "#fff",
    fontSize: "14px",
    fontWeight: "500",
    opacity: "85%",
    "&[disabled]": { background: "#bebebe !important", pointerEvents: "none" },
    cursor: "pointer",
  },
  thankyou_div_style: {
    padding: "8px 100px",
    height: "30px",
    display: "flex",
    borderRadius: "8px",
    boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
    alignItems: "center",
    letterSpacing: "0.05rem",
    background: "#008000",
    color: "#fff",
    fontSize: "16px",
    fontWeight: "500",
    opacity: "85%",
    marginTop: "1rem",
  },
  command_btn_div: { justifyContent: "space-around", flexDirection: "row", display: "flex" },
  session_end_div: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
});
