import { service, httpMethods, requests, authConst } from "../constants/constants";
import NetworkManger from "@networkManager";
import store from "../config/store";
import server from "../config/api.json";

const userServiceApi = server.service.user;
const requestType = process.env.REACT_APP_REQUEST_TYPE;

class networkAdapter {
  getDomain(path, options) {
    switch (options?.service) {
      case service.CHARGE_SERVICE:
        return process.env.REACT_APP_CHARGE_ENDPOINT + path;
      case service.USER_SERVICE:
        return process.env.REACT_APP_USER_ENDPOINT + path;
      default:
        return process.env.REACT_APP_CHARGE_ENDPOINT + path;
    }
  }

  getParams(queryParams = {}) {
    return queryParams;
  }

  //POST
  async makePostRequest(url, body, options) {
    const state = store.getState();
    url = this.getDomain(url, options);
    const postData = {
      method: httpMethods.POST,
      data: body,
    };

    options = {
      ...options,
      requestType: requestType,
      userDetails: state?.authorize?.token,
      accountId: options.accountId ? options.accountId : state?.authorize?.userAccountId,
      clientId: authConst.clientId,
      clientSecret: authConst.clientSecret,
      refreshApiUrl: `${process.env.REACT_APP_USER_ENDPOINT}${userServiceApi.refresh_token}`,
    };

    let apiResponse = await NetworkManger.handleRequest(url, postData, options, store);
    if (apiResponse && apiResponse.code === requests.TOKEN_FAILED) {
      // store.dispatch(setStoreData(TOKEN, null));
    } else if (apiResponse.code === requests.ERR_NETWORK) {
      return {
        status_code: 500,
        data: undefined,
      };
    } else {
      return apiResponse?.response?.data ? apiResponse?.response?.data : apiResponse?.data;
    }
  }

  async makePutRequest(url, body, options) {
    const state = store.getState();
    url = this.getDomain(url, options);
    const postData = {
      method: httpMethods.PUT,
      data: body,
    };
    options = {
      ...options,
      requestType: requestType,
      userDetails: state?.authorize?.token,
      accountId: state?.authorize?.userAccountId,
      clientId: authConst.clientId,
      clientSecret: authConst.clientSecret,
      refreshApiUrl: `${process.env.REACT_APP_USER_ENDPOINT}${userServiceApi.refresh_token}`,
    };
    let apiResponse = await NetworkManger.handleRequest(url, postData, options, store);
    if (apiResponse && apiResponse.code === requests.TOKEN_FAILED) {
      // store.dispatch(setStoreData(TOKEN, null));
    } else if (apiResponse.code === requests.ERR_NETWORK) {
      return {
        status_code: 500,
        data: undefined,
      };
    } else {
      return apiResponse?.response?.data ? apiResponse?.response?.data : apiResponse?.data;
    }
  }

  async makeDeleteRequest(url, options, body) {
    const state = store.getState();
    url = this.getDomain(url, options);
    const postData = {
      method: httpMethods.DELETE,
      data: body,
    };
    options = {
      ...options,
      requestType: requestType,
      userDetails: state?.authorize?.token,
      accountId: state?.authorize?.userAccountId,
      clientId: authConst.clientId,
      clientSecret: authConst.clientSecret,
      refreshApiUrl: `${process.env.REACT_APP_USER_ENDPOINT}${userServiceApi.refresh_token}`,
    };
    let apiResponse = await NetworkManger.handleRequest(url, postData, options, store);
    if (apiResponse && apiResponse.code === requests.TOKEN_FAILED) {
      // store.dispatch(setStoreData(TOKEN, null));
    } else if (apiResponse.code === requests.ERR_NETWORK) {
      return {
        status_code: 500,
        data: undefined,
      };
    } else {
      return apiResponse?.response?.data ? apiResponse?.response?.data : apiResponse?.data;
    }
  }

  async makeGetRequest(path, options) {
    const state = store.getState();
    path = this.getDomain(path, options);
    const getData = {
      method: httpMethods.GET,
    };
    options = {
      ...options,
      requestType: requestType,
      userDetails: state?.authorize?.token,
      accountId: state?.authorize?.userAccountId,
      clientId: authConst.clientId,
      clientSecret: authConst.clientSecret,
      refreshApiUrl: `${process.env.REACT_APP_USER_ENDPOINT}${userServiceApi.refresh_token}`,
    };
    let apiResponse = await NetworkManger.handleRequest(path, getData, options, store);

    if (apiResponse && apiResponse.code === requests.TOKEN_FAILED) {
      // store.dispatch(setStoreData(TOKEN, null));
    } else if (apiResponse.code === requests.ERR_NETWORK) {
      return {
        status_code: 500,
        data: undefined,
      };
    } else {
      if (!apiResponse.data && !apiResponse?.response?.data) {
        // store.dispatch(setLoader(false));
        // errorToaster(cacheConstants.ERROR);
      }
      return apiResponse?.response?.data ? apiResponse?.response?.data : apiResponse?.data;
    }
  }

  async makePatchRequest(url, body, options) {
    const state = store.getState();
    url = this.getDomain(url, options);
    const postData = {
      method: httpMethods.PATCH,
      data: body,
    };
    options = {
      ...options,
      requestType: requestType,
      userDetails: state?.authorize?.token,
      accountId: state?.authorize?.userAccountId,
      clientId: authConst.clientId,
      clientSecret: authConst.clientSecret,
      refreshApiUrl: `${process.env.REACT_APP_USER_ENDPOINT}${userServiceApi.refresh_token}`,
    };
    let apiResponse = await NetworkManger.handleRequest(url, postData, options, store);
    if (apiResponse && apiResponse.code === requests.TOKEN_FAILED) {
      // store.dispatch(setStoreData(TOKEN, null));
    } else if (apiResponse.code === requests.ERR_NETWORK) {
      return {
        status_code: 500,
        data: undefined,
      };
    } else {
      return apiResponse?.response?.data ? apiResponse?.response?.data : apiResponse?.data;
    }
  }
}

const NetworkHandler = new networkAdapter();
export default NetworkHandler;
