import * as actionTypes from "./types";
import networkAdapter from "../helpers/network-handler";
import server from "../config/api.json";
import { service } from "../constants/constants";

const authService = server.service.authorise;
const userApi = server.service.user;
const chargingApi = server.service.charging;
const transactionApi = server.service.transaction;

export const fetchClientToken = (reqParam, accountId) => async (dispatch) => {
  let response = await networkAdapter.makePostRequest(userApi.token, reqParam, {
    service: service.USER_SERVICE,
    actionType: actionTypes.TOKEN,
    accountId: accountId,
  });
  if (response) {
    dispatch({
      type: actionTypes.TOKEN,
      payload: response?.data,
    });
  }
};

export const setStoreData = (type, value) => async (dispatch) => {
  dispatch({ type: type, payload: value });
};

export const checkAuthorize = (params) => async (dispatch) => {
  let response = await networkAdapter.makePostRequest(authService.web_auth, params, {
    service: service.CHARGE_SERVICE,
    type: actionTypes.AUTHORISE,
  });
  if (response) {
    dispatch({
      type: actionTypes.AUTHORISE,
      payload: response,
    });
  }
};

export const checkInitCharging = (params) => async (dispatch) => {
  let response = await networkAdapter.makeGetRequest(
    `${authService.init_charging}/${params.reservationId}?userType=${params.userType}`,
    {
      service: service.CHARGE_SERVICE,
      type: actionTypes.INIT_CHARGE_CHECK,
    }
  );
  if (response) {
    dispatch({
      type: actionTypes.INIT_CHARGE_CHECK,
      payload: response,
    });
  }
};
export const fetchChargeStatus = (params) => async (dispatch) => {
  let response = await networkAdapter.makeGetRequest(
    `${authService.status}?terminalCode=${params.terminalCode}&reservationId=${params.reservationId}&userType=${params.userType}`,
    {
      service: service.CHARGE_SERVICE,
      type: actionTypes.CHARGE_STATUS,
    }
  );
  if (response) {
    dispatch({
      type: actionTypes.CHARGE_STATUS,
      payload: response,
    });
  }
};

export const fetchChargeHistory = (param) => async (dispatch) => {
  let response = await networkAdapter.makeGetRequest(
    `${authService.history}?reservationId=${param.id}&startDate=${param.startDate}&endDate=${param.endDate}&userType=${param.userType}`,
    {
      service: service.CHARGE_SERVICE,
      type: actionTypes.CHARGE_HISTORY,
    }
  );
  if (response) {
    dispatch({
      type: actionTypes.CHARGE_HISTORY,
      payload: response,
    });
  }
};

export const triggerCommnad = (params) => async (dispatch) => {
  let response = await networkAdapter.makePostRequest(authService.command, params, {
    service: service.CHARGE_SERVICE,
    type: actionTypes.COMMAND,
  });
  if (response) {
    dispatch({
      type: actionTypes.COMMAND,
      payload: response,
    });
  }
};

export const checkChargeSession = (authDetails) => async (dispatch) => {
  let response = await networkAdapter.makeGetRequest(
    `${chargingApi.charge}/${authDetails.reservationId}?terminalCode=${authDetails.terminalCode}&userType=${authDetails.userType}`,
    {
      service: service.CHARGE_SERVICE,
      type: actionTypes.CHARGING_SESSION,
    }
  );
  if (response) {
    dispatch({
      type: actionTypes.CHARGING_SESSION,
      payload: response,
    });
  }
};

export const setLoader = (value) => (dispatch) => {
  //Return an action
  dispatch({ type: actionTypes.LOADER, payload: value });
};

export const postFeedBack = (params) => async (dispatch) => {
  let response = await networkAdapter.makePostRequest(chargingApi.feedback, params, {
    service: service.CHARGE_SERVICE,
    type: actionTypes.FEEDBACK,
  });
  if (response) {
    dispatch({
      type: actionTypes.FEEDBACK,
      payload: response,
    });
  }
};

export const getStationDetails = (code) => async (dispatch) => {
  let response = await networkAdapter.makeGetRequest(`${transactionApi.station}/${code}`, {
    service: service.CHARGE_SERVICE,
    type: actionTypes.GET_STATION_DETAILS,
  });
  if (response) {
    dispatch({
      type: actionTypes.GET_STATION_DETAILS,
      payload: response,
    });
  }
};

export const createTransaction = (params) => async (dispatch) => {
  let response = await networkAdapter.makePostRequest(transactionApi.initiate, params, {
    service: service.CHARGE_SERVICE,
    type: actionTypes.MAKE_TRANSACTION,
  });
  if (response) {
    dispatch({
      type: actionTypes.MAKE_TRANSACTION,
      payload: response,
    });
  }
};

// --------------Stripe Pid-------------
export const getPidStripe = (params) => async (dispatch) => {
  let response = await networkAdapter.makePostRequest(transactionApi.pid_stripe, params, {
    service: service.CHARGE_SERVICE,
    type: actionTypes.STRIPE_PID,
  });
  if (response) {
    dispatch({
      type: actionTypes.STRIPE_PID,
      payload: response,
    });
  }
};
