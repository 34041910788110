export const authConstants = {
  URL_PARAM: "sid",
  REFRESH_PARAM:"refid",
  ACCOUNT_PARAM: "dn",
  EXPIRES_IN: "expires_in",
  REFRESH_EXPIRES_IN: "refresh_expires_in",
  HOST_URL: "host_url",
  CLIENT: "client",
};

export const envConstants = {
  DEVELOP: "development",
  QA: "qa",
};

export const grantConst = {
  refresh_token: "refresh_token",
};
