import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import React from "react";

export default function ConfirmationDialog({
  open,
  handleClose,
  dialogueConfig,
  handleDialogueCancel,
  handleDialogueSubmit,
  hideButton,
  hideCancelButton
}) {
  return (
    <Dialog
      className="delete-popup"
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{ zIndex: "99999" }}
    >
      {" "}
      <DialogTitle
        id="alert-dialog-title"
        sx={{ ...dialogueConfig?.titleStyle, marginTop: "10px !important" }}
      >
        {dialogueConfig?.dialogIcon ? (
          <Box sx={dialogueConfig?.iconBoxStyle}>
            <img
              src={dialogueConfig?.dialogIcon}
              alt="logo"
              style={dialogueConfig?.dialogIconStyle}
            />
          </Box>
        ) : (
          ""
        )} 
       {dialogueConfig?.title && (
          <Typography sx={dialogueConfig?.titleStyle}>
            {dialogueConfig?.title}
          </Typography>
        )} 
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" sx={dialogueConfig?.contentStyle}>
          {dialogueConfig?.content}
        </DialogContentText>
      </DialogContent>
      
      <DialogActions sx={{ justifyContent: "center", marginBottom: "20px" }}>
      {!hideButton &&
      <>
      {!hideCancelButton && (
        <button
          type="button"
          onClick={() => handleDialogueCancel()}
          style={{
            ...dialogueConfig?.cancelButtonStyle,
            textTransform: "capitalize",
          }}
        >
          {dialogueConfig?.cancelButtonLabel}
        </button>
      )}
        <button
          type="button"
          onClick={() => handleDialogueSubmit()}
          style={{
            ...dialogueConfig?.submitButtonStyle,
            marginLeft: "20px !important",
            textTransform: "capitalize",
          }}
        >
          {dialogueConfig?.submitButtonLabel}
        </button>
          </>
}
      </DialogActions>
    </Dialog>
  );
}
