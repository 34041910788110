import React from "react";
import ConfirmationDialog from "../../library/ConfirmationDialog/confimationDialog";
import {
  alertDialogueConfig,
  chargingErrorConfig,
  errorConfig,
  finishingConfig,
  paymentDialogConfig,
  preparingDialogueConfig,
} from "../../utils/dialog-config-utils";
import { setLoader } from "../../services";
import { useLocation } from "react-router-dom";

function DialogComponent({ openDialog, setOpenDialog, dispatch, authorize }) {
  const location = useLocation();
  return (
    <>
      <ConfirmationDialog
        open={openDialog.payment}
        dialogueConfig={paymentDialogConfig()}
        handleClose={() => {}}
        handleDialogueCancel={() => {}}
        handleDialogueSubmit={() => {}}
        hideButton={true}
      />
      <ConfirmationDialog
        open={openDialog.chargingError}
        dialogueConfig={chargingErrorConfig()}
        handleClose={() => {}}
        handleDialogueCancel={() => {}}
        handleDialogueSubmit={() => {
          setOpenDialog({
            preparing: false,
            ...openDialog,
            chargingError: false,
          });
          dispatch(setLoader(true));
          window.location.href = `/${location.pathname}`;
        }}
        hideButton={false}
        hideCancelButton={true}
      />
      <ConfirmationDialog
        open={openDialog.statusAlert}
        dialogueConfig={alertDialogueConfig(authorize.chargeStatus?.data)}
        handleClose={() => {}}
        handleDialogueCancel={() => {}}
        handleDialogueSubmit={() => {}}
        hideButton={true}
      />
      <ConfirmationDialog
        open={openDialog.finish}
        dialogueConfig={finishingConfig()}
        handleClose={() => {}}
        handleDialogueCancel={() => {}}
        handleDialogueSubmit={() => {}}
        hideButton={true}
      />
      <ConfirmationDialog
        open={openDialog.preparing}
        dialogueConfig={preparingDialogueConfig()}
        handleClose={() => {}}
        handleDialogueCancel={() => {}}
        handleDialogueSubmit={() => {}}
        hideButton={true}
      />
      <ConfirmationDialog
        open={openDialog.error}
        dialogueConfig={errorConfig()}
        handleClose={() => {}}
        handleDialogueCancel={() => {}}
        handleDialogueSubmit={() => {
          setOpenDialog({
            ...openDialog,
            error: false,
          });
          window.location.href = `/${location.pathname}`;
        }}
        hideButton={false}
        hideCancelButton={true}
      />
    </>
  );
}

export default DialogComponent;
