export const commandRequestUtil = (command, authorize, id, type) => {

  return {
    command: command.toString(),
    transactionData: {
      chargingTerminalCode: authorize.terminalDetails?.terminalCode,
      connectorId: authorize.terminalDetails?.connectorId
        ? authorize.terminalDetails?.connectorId
        : 1,
    },
    id: id,
    userType: type,
  };
};
