export const dialogueConfig = {
  titleStyle: {},
  contentStyle: {
    color: "#333333",
    fontSize: "17px",
    fontWeight: 600,
    padding: "10px 30px !important",
  },
  content: "Press 'End' to stop charging",
  cancelButtonStyle: {
    padding: "8px 2.1875rem",
    border: "solid 1px #d3d3d3",
    backgroundColor: "transparent",
    height: "33px",
    display: "flex",
    borderRadius: "5px",
    alignItems: "center",
    letterSpacing: "0.00938em",
    color: "#333333",
    fontSize: "16px",
    fontWeight: "800",
    width: "100px",
    justifyContent: "center",
    boxShadow: "rgb(211,211,211) 0px 2px 8px 0px",
    cursor: "pointer",
  },
  cancelButtonLabel: "No",
  submitButtonStyle: {
    padding: "8px 2.1875rem",
    border: "solid 1px #d3d3d3",
    height: "33px",
    display: "flex",
    width: "100px",
    justifyContent: "center",
    borderRadius: "5px",
    alignItems: "center",
    letterSpacing: "0.00938em",
    background: "transparent",
    color: "#D81D1D",
    fontSize: "16px",
    fontWeight: "800",
    opacity: "85%",
    cursor: "pointer",
    boxShadow: "rgb(211,211,211) 0px 2px 8px 0px",
    "&[disabled]": { opacity: "0.7", pointerEvents: "none" },
  },
  submitButtonLabel: "End",
};
export const alertDialogueConfig = (data) => ({
  titleStyle: {
    color: "#000000",
    fontSize: "25px",
    fontWeight: 800,
    padding: "0px 8px !important",
    display: "flex",
    justifyContent: "center",
    marginBottom: "8px",
    marginTop: "5px",
  },
  contentStyle: {
    color: "#333333",
    fontSize: "18px",
    fontWeight: 600,
    padding: "0px 8px !important",
    display: "flex",
    justifyContent: "center",
    marginTop: "1rem",
  },
  content:
    "Latest information not available now. Last state received  as " +
    data?.status +
    ". Updated at " +
    data?.time +
    ". Please check after some time.",
  cancelButtonStyle: {},
  cancelButtonLabel: "",
  submitButtonStyle: {
    padding: "8px 2.1875rem",
    border: "solid 1px #d3d3d3",
    height: "33px",
    display: "flex",
    width: "100px",
    justifyContent: "center",
    borderRadius: "5px",
    alignItems: "center",
    letterSpacing: "0.00938em",
    background: "transparent",
    color: "#008000",
    fontSize: "16px",
    fontWeight: "800",
    opacity: "85%",
    cursor: "pointer",
    boxShadow: "rgb(211,211,211) 0px 2px 8px 0px",
    "&[disabled]": { opacity: "0.7", pointerEvents: "none" },
  },
  submitButtonLabel: "Ok",
});
export const preparingDialogueConfig = () => ({
  titleStyle: {
    color: "#000000",
    fontSize: "25px",
    fontWeight: 800,
    padding: "0px 8px !important",
    display: "flex",
    justifyContent: "center",
    marginBottom: "8px",
    marginTop: "5px",
  },
  title: "Preparing...",
  contentStyle: {
    color: "#333333",
    fontSize: "17px",
    fontWeight: 600,
    padding: "0px 8px !important",
    display: "flex",
    justifyContent: "center",
  },
  content: "Connect charging cable to vehicle.",
  cancelButtonStyle: {},
  cancelButtonLabel: "",
  submitButtonStyle: {
    padding: "8px 2.1875rem",
    border: "solid 1px #d3d3d3",
    height: "33px",
    display: "flex",
    width: "100px",
    justifyContent: "center",
    borderRadius: "5px",
    alignItems: "center",
    letterSpacing: "0.00938em",
    background: "transparent",
    color: "#008000",
    fontSize: "16px",
    fontWeight: "800",
    opacity: "85%",
    cursor: "pointer",
    boxShadow: "rgb(211,211,211) 0px 2px 8px 0px",
    "&[disabled]": { opacity: "0.7", pointerEvents: "none" },
  },
  submitButtonLabel: "Ok",
});
export const finishingConfig = () => ({
  titleStyle: {
    color: "#000000",
    fontSize: "25px",
    fontWeight: 800,
    padding: "0px 8px !important",
    display: "flex",
    justifyContent: "center",
    marginBottom: "8px",
    marginTop: "5px",
  },
  title: "Finishing...",
  contentStyle: {
    color: "#333333",
    fontSize: "17px",
    fontWeight: 600,
    padding: "0px 8px !important",
    display: "flex",
    justifyContent: "center",
  },
  content: "Finishing... Please wait.",
  cancelButtonStyle: {},
  cancelButtonLabel: "",
  submitButtonStyle: {
    padding: "8px 2.1875rem",
    border: "solid 1px #d3d3d3",
    height: "33px",
    display: "flex",
    width: "100px",
    justifyContent: "center",
    borderRadius: "5px",
    alignItems: "center",
    letterSpacing: "0.00938em",
    background: "transparent",
    color: "#008000",
    fontSize: "16px",
    fontWeight: "800",
    opacity: "85%",
    cursor: "pointer",
    boxShadow: "rgb(211,211,211) 0px 2px 8px 0px",
    "&[disabled]": { opacity: "0.7", pointerEvents: "none" },
  },
  submitButtonLabel: "Ok",
});
export const errorConfig = () => ({
  titleStyle: {
    color: "#000000",
    fontSize: "25px",
    fontWeight: 800,
    padding: "0px 8px !important",
    display: "flex",
    justifyContent: "center",
    marginBottom: "8px",
    marginTop: "5px",
  },
  contentStyle: {
    color: "#333333",
    fontSize: "17px",
    fontWeight: 600,
    padding: "0px 8px !important",
    display: "flex",
    justifyContent: "center",
  },
  content: "Something went wrong.",
  cancelButtonStyle: {},
  cancelButtonLabel: "",
  submitButtonStyle: {
    padding: "8px 2.1875rem",
    border: "solid 1px #d3d3d3",
    height: "33px",
    display: "flex",
    width: "100px",
    justifyContent: "center",
    borderRadius: "5px",
    alignItems: "center",
    letterSpacing: "0.00938em",
    background: "transparent",
    color: "#008000",
    fontSize: "16px",
    fontWeight: "800",
    opacity: "85%",
    cursor: "pointer",
    boxShadow: "rgb(211,211,211) 0px 2px 8px 0px",
    "&[disabled]": { opacity: "0.7", pointerEvents: "none" },
  },
  submitButtonLabel: "Ok",
});
export const chargingErrorConfig = () => ({
  titleStyle: {
    color: "#000000",
    fontSize: "25px",
    fontWeight: 800,
    padding: "0px 8px !important",
    display: "flex",
    justifyContent: "center",
    marginBottom: "8px",
    marginTop: "5px",
  },
  iconBoxStyle: { padding: "3% 0% 0% 0%" },
  dialogIcon:
    "https://emaestro-svp-cms.s3.amazonaws.com/assets/3ba0077c-67e1-43c3-b41e-d3b13007dc99.png",

  contentStyle: {
    color: "#333333",
    fontSize: "17px",
    fontWeight: 600,
    padding: "0px 7px !important",
    display: "flex",
    justifyContent: "center",
  },
  content: "This email address is already in use. Please try again later or use another email.",
  cancelButtonStyle: {},
  cancelButtonLabel: "",
  submitButtonStyle: {
    padding: "8px 2.1875rem",
    border: "solid 1px #d3d3d3",
    height: "33px",
    display: "flex",
    width: "100px",
    justifyContent: "center",
    borderRadius: "5px",
    alignItems: "center",
    letterSpacing: "0.00938em",
    background: "transparent",
    color: "#008000",
    fontSize: "16px",
    fontWeight: "800",
    opacity: "85%",
    cursor: "pointer",
    boxShadow: "rgb(211,211,211) 0px 2px 8px 0px",
    "&[disabled]": { opacity: "0.7", pointerEvents: "none" },
  },
  submitButtonLabel: "Ok",
});

export const paymentDialogConfig = (type) => ({
  titleStyle: {
    color: "#000000",
    fontSize: "25px",
    fontWeight: 800,
    padding: "0px 8px !important",
    display: "flex",
    justifyContent: "center",
    marginBottom: "8px",
    marginTop: "5px",
  },
  title: "Payment Processing...",
  contentStyle: {
    color: "#333333",
    fontSize: "17px",
    fontWeight: 600,
    padding: "0px 8px !important",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  content: "Your payment is processing. Please wait..",
  cancelButtonStyle: {},
  cancelButtonLabel: "",
  submitButtonStyle: {
    padding: "8px 2.1875rem",
    border: "solid 1px #d3d3d3",
    height: "33px",
    display: "flex",
    width: "100px",
    justifyContent: "center",
    borderRadius: "5px",
    alignItems: "center",
    letterSpacing: "0.00938em",
    background: "transparent",
    color: "#008000",
    fontSize: "16px",
    fontWeight: "800",
    opacity: "85%",
    cursor: "pointer",
    boxShadow: "rgb(211,211,211) 0px 2px 8px 0px",
    "&[disabled]": { opacity: "0.7", pointerEvents: "none" },
  },
  submitButtonLabel: "Ok",
});

export const transactionErrorConfig = () => ({
  titleStyle: {
    color: "#000000",
    fontSize: "25px",
    fontWeight: 800,
    padding: "0px 8px !important",
    display: "flex",
    justifyContent: "center",
    marginBottom: "8px",
    marginTop: "5px",
  },
  contentStyle: {
    color: "#333333",
    fontSize: "17px",
    fontWeight: 600,
    padding: "0px 8px !important",
    display: "flex",
    justifyContent: "center",
  },
  title: "Transaction Error",
  content: "Your transaction is not completed. Please use valid card details.",
  cancelButtonStyle: {},
  cancelButtonLabel: "",
  submitButtonStyle: {
    padding: "8px 2.1875rem",
    border: "solid 1px #d3d3d3",
    height: "33px",
    display: "flex",
    width: "100px",
    justifyContent: "center",
    borderRadius: "5px",
    alignItems: "center",
    letterSpacing: "0.00938em",
    background: "transparent",
    color: "#008000",
    fontSize: "16px",
    fontWeight: "800",
    opacity: "85%",
    cursor: "pointer",
    boxShadow: "rgb(211,211,211) 0px 2px 8px 0px",
    "&[disabled]": { opacity: "0.7", pointerEvents: "none" },
  },
  submitButtonLabel: "Ok",
});

export const stationErrorDialog = () => ({
  titleStyle: {
    color: "#000000",
    fontSize: "25px",
    fontWeight: 800,
    padding: "0px 8px !important",
    display: "flex",
    justifyContent: "center",
    marginBottom: "8px",
    marginTop: "5px",
  },
  title: "",
  contentStyle: {
    color: "#333333",
    fontSize: "17px",
    fontWeight: 600,
    padding: "0px 8px !important",
    display: "flex",
    justifyContent: "center",
  },
  content: "There is no user preference added to this station. Please contact administrator",
  cancelButtonStyle: {},
  cancelButtonLabel: "",
  submitButtonStyle: {
    padding: "8px 2.1875rem",
    border: "solid 1px #d3d3d3",
    height: "33px",
    display: "flex",
    width: "100px",
    justifyContent: "center",
    borderRadius: "5px",
    alignItems: "center",
    letterSpacing: "0.00938em",
    background: "transparent",
    color: "#008000",
    fontSize: "16px",
    fontWeight: "800",
    opacity: "85%",
    cursor: "pointer",
    boxShadow: "rgb(211,211,211) 0px 2px 8px 0px",
    "&[disabled]": { opacity: "0.7", pointerEvents: "none" },
  },
  submitButtonLabel: "Ok",
});

export const paymentConfirmDialogueConfig = () => ({
  titleStyle: {
    color: "#000000",
    fontSize: "25px",
    fontWeight: 800,
    padding: "0px 8px !important",
    display: "flex",
    justifyContent: "center",
    marginBottom: "8px",
    marginTop: "5px",
  },
  title: "Payment Processing...",
  contentStyle: {
    color: "#333333",
    fontSize: "17px",
    fontWeight: 600,
    padding: "0px 8px !important",
    display: "flex",
    justifyContent: "center",
  },
  content: "Payment Processing... Please wait.",
  cancelButtonStyle: {},
  cancelButtonLabel: "",
  submitButtonStyle: {
    padding: "8px 2.1875rem",
    border: "solid 1px #d3d3d3",
    height: "33px",
    display: "flex",
    width: "100px",
    justifyContent: "center",
    borderRadius: "5px",
    alignItems: "center",
    letterSpacing: "0.00938em",
    background: "transparent",
    color: "#008000",
    fontSize: "16px",
    fontWeight: "800",
    opacity: "85%",
    cursor: "pointer",
    boxShadow: "rgb(211,211,211) 0px 2px 8px 0px",
    "&[disabled]": { opacity: "0.7", pointerEvents: "none" },
  },
  submitButtonLabel: "Ok",
});

export const chargeDurationConfig = (duration) => ({
  titleStyle: {
    color: "#000000",
    fontSize: "25px",
    fontWeight: 800,
    padding: "0px 8px !important",
    display: "flex",
    justifyContent: "center",
    marginBottom: "8px",
    marginTop: "5px",
  },
  contentStyle: {
    color: "#333333",
    fontSize: "17px",
    fontWeight: 600,
    padding: "0px 8px !important",
    display: "flex",
    justifyContent: "center",
  },
  // title: "Transaction Error",
  content: `You have ${duration} left for charging`,
  cancelButtonStyle: {},
  cancelButtonLabel: "",
  submitButtonStyle: {
    padding: "8px 2.1875rem",
    border: "solid 1px #d3d3d3",
    height: "33px",
    display: "flex",
    width: "100px",
    justifyContent: "center",
    borderRadius: "5px",
    alignItems: "center",
    letterSpacing: "0.00938em",
    background: "transparent",
    color: "#008000",
    fontSize: "16px",
    fontWeight: "800",
    opacity: "85%",
    cursor: "pointer",
    boxShadow: "rgb(211,211,211) 0px 2px 8px 0px",
    "&[disabled]": { opacity: "0.7", pointerEvents: "none" },
  },
  submitButtonLabel: "Ok",
});

export const connectorErrorDialogueConfig = () => ({
  titleStyle: {
    color: "#000000",
    fontSize: "20px",
    fontWeight: 800,
    padding: "0px 8px !important",
    display: "flex",
    justifyContent: "center",
    marginBottom: "8px",
    marginTop: "5px",
  },
  title: "Charging Terminal Unavailable...",
  contentStyle: {
    color: "#333333",
    fontSize: "17px",
    fontWeight: 600,
    padding: "0px 8px !important",
    display: "flex",
    justifyContent: "center",
  },
  content: "Please wait until the charging terminal is online.",
  cancelButtonStyle: {},
  cancelButtonLabel: "",
  submitButtonStyle: {
    padding: "8px 2.1875rem",
    border: "solid 1px #d3d3d3",
    height: "33px",
    display: "flex",
    width: "100px",
    justifyContent: "center",
    borderRadius: "5px",
    alignItems: "center",
    letterSpacing: "0.00938em",
    background: "transparent",
    color: "#008000",
    fontSize: "16px",
    fontWeight: "800",
    opacity: "85%",
    cursor: "pointer",
    boxShadow: "rgb(211,211,211) 0px 2px 8px 0px",
    "&[disabled]": { opacity: "0.7", pointerEvents: "none" },
  },
  submitButtonLabel: "Ok",
});

export const connectorInUseDialogueConfig = () => ({
  titleStyle: {
    color: "#000000",
    fontSize: "20px",
    fontWeight: 800,
    padding: "0px 8px !important",
    display: "flex",
    justifyContent: "center",
    marginBottom: "8px",
    marginTop: "5px",
  },
  title: "Charging In-Progress...",
  contentStyle: {
    color: "#333333",
    fontSize: "17px",
    fontWeight: 600,
    padding: "0px 8px !important",
    display: "flex",
    justifyContent: "center",
  },
  content: "Please check your mail to know the current status if you started charging.",
  cancelButtonStyle: {},
  cancelButtonLabel: "",
  submitButtonStyle: {
    padding: "8px 2.1875rem",
    border: "solid 1px #d3d3d3",
    height: "33px",
    display: "flex",
    width: "100px",
    justifyContent: "center",
    borderRadius: "5px",
    alignItems: "center",
    letterSpacing: "0.00938em",
    background: "transparent",
    color: "#008000",
    fontSize: "16px",
    fontWeight: "800",
    opacity: "85%",
    cursor: "pointer",
    boxShadow: "rgb(211,211,211) 0px 2px 8px 0px",
    "&[disabled]": { opacity: "0.7", pointerEvents: "none" },
  },
  submitButtonLabel: "Ok",
});

export const instructionDialogueConfig = () => ({
  titleStyle: {
    color: "#000000",
    fontSize: "20px",
    fontWeight: 800,
    padding: "0px 8px !important",
    display: "flex",
    justifyContent: "center",
    marginBottom: "8px",
    marginTop: "5px",
  },
  title: "Instructions",
  contentStyle: {
    color: "#333333",
    fontSize: "17px",
    fontWeight: 600,
    padding: "0px 8px !important",
    display: "flex",
    justifyContent: "center",
  },
  content: "Please wait until the charging terminal is online.",
  cancelButtonStyle: {},
  cancelButtonLabel: "",
  submitButtonStyle: {
    padding: "8px 2.1875rem",
    border: "solid 1px #d3d3d3",
    height: "33px",
    display: "flex",
    width: "100px",
    justifyContent: "center",
    borderRadius: "5px",
    alignItems: "center",
    letterSpacing: "0.00938em",
    background: "transparent",
    color: "#008000",
    fontSize: "16px",
    fontWeight: "800",
    opacity: "85%",
    cursor: "pointer",
    boxShadow: "rgb(211,211,211) 0px 2px 8px 0px",
    "&[disabled]": { opacity: "0.7", pointerEvents: "none" },
  },
  submitButtonLabel: "Ok",
});
