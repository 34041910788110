import { cacheConstants, statusCodes } from "../constants/constants";

class CacheManager {
  getCachedData = (pageCode) => JSON.parse(localStorage.getItem(pageCode));

  handleResponse = (response, options) => {
    // use any dispatchers/method to communicate this data to the store/view
    if (response.status === statusCodes.successCode1) {
      let pageCode = "";
      if (options.isCmsConfig) {
        pageCode = response.data.data.profile.name;
        localStorage.setItem("ceProfileId", response.data.data.id);
      } else {
        pageCode = options.cacheKey ? options.cacheKey : response?.data?.data?.code;
      }
      if (options.cache) {
        const cachedData = {
          headers: response.headers[cacheConstants.LAST_MODIFIED]
            ? response.headers[cacheConstants.LAST_MODIFIED]
            : null,
          responseData: response.data.data,
        };
        localStorage.setItem(pageCode, JSON.stringify(cachedData));
      }
    }
    return response;
  };
}

const cacheManager = new CacheManager();
export default cacheManager;
