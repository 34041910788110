import { toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//SUCCESS
export function successToaster(message) {
  toast.success(message, {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    transition: Zoom,
  });
}
//ERROR
export function errorToaster(message) {
  toast.error(message, {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    transition: Zoom,
  });
}
