import React from "react";
import "./style.scss";

export default function TextArea({
  input,
  type,
  placeholder,
  label,
  disabled,
  className,
  handleChange,
  maxLength,
  textareaValue,
  meta,
  onKeyDown
}) {
  return (
    <div>
      <textarea
        {...input}
        type={type}
        placeholder={placeholder}
        className={className}
        label={label}
        disabled={disabled}
        value={textareaValue ? textareaValue : input ? input.value : ""}
        maxLength={maxLength}
        onChange={(e) => {
          input && input.onChange(e.target.value);
          handleChange && handleChange(e);
        }}
        onKeyDown={onKeyDown}
      />
      <div className="validation-error">
        {meta && meta.touched && ((meta && meta.error && <span>{meta.error}</span>) || (meta && meta.warning && <span>{meta.warning}</span>))}
      </div>
    </div>
  );
}
