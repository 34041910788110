import { isEmpty } from "underscore";
import { paths, paymentModeTypes } from "../constants/constants";

export const routeCheck = (pathname) => {
  let isExist = paths.find((elem) => elem === pathname);
  return isExist ? true : false;
};

export const secondsToHms = (seconds) => {
  var hours = Math.floor(seconds / 3600);

  var remainingSeconds = seconds % 3600;

  var minutes = Math.floor(remainingSeconds / 60);
  // Calculate remaining seconds after converting to minutes
  remainingSeconds = remainingSeconds % 60;

  // Return the result as a string in the format "hours:minutes:seconds"
  return hours + ":" + minutes + ":" + remainingSeconds + " Hours";
};

export const convertSecondsToTime = (seconds) => {
  // Calculate hours, minutes, and remaining seconds
  var hours = Math.floor(seconds / 3600);
  var minutes = Math.floor((seconds % 3600) / 60);
  var remainingSeconds = seconds % 60;

  // Format the result
  var result = "";
  if (hours > 0) {
    result += hours + "h ";
  }
  if (minutes > 0 || hours > 0) {
    result += minutes + "m ";
  }
  result += remainingSeconds + "s ";

  return result;
};

export const checkCardFormValid = (values) => {
  let valid = false;
  if (!isEmpty(values)) {
    if (
      values.card_number &&
      values.card_holder_name &&
      values.security_code &&
      values.exp_date &&
      values.email
    ) {
      valid = true;
    }
  }
  return valid;
};

export const checkFormValid = (values) => {
  let valid = false;
  if (!isEmpty(values)) {
    if (values.webId) {
      valid = true;
    }
  }
  return valid;
};

export const priceUnit = (unit) => {
  switch (unit) {
    case "USD":
      return "$";
    case "INR":
      return "₹";
    default:
      return "";
  }
};

export const convertAscii = (value) => {
  let data = value.split("+");
  let rev_value = "";
  if (data.length === 2 && data[0] === "") {
    rev_value = `${data[0]}%2B${data[1]}`;
  } else {
    rev_value = value;
  }
  return rev_value;
};

export const getPricinType = (type, pricingStrategy) => {
  switch (type) {
    case "UNLIMITED_PER_SESSION":
      return "Session";
    case "UNLIMITED_PER_DAY":
      return "Day";
    case "TIME":
      return !pricingStrategy
        ? "Time"
        : pricingStrategy === "ENERGY"
        ? "Energy"
        : pricingStrategy === "HOUR"
        ? "Hour"
        : pricingStrategy === "MINUTE"
        ? "Minute"
        : "Time";
    case "SESSION":
      return "Session";
    default:
      return "";
  }
};

export const getPaymentModeType = (stationData) => {
  // console.log("stationData ", stationData.channels);
  let pricing = stationData.channels.find((elem) => elem.chargeUserType === 1);
  if (!isEmpty(pricing) && !isEmpty(pricing.chargePriceDetails)) {
    if (
      pricing.chargePriceDetails[0].pricingType === "UNLIMITED_PER_SESSION" ||
      pricing.chargePriceDetails[0].pricingType === "UNLIMITED_PER_DAY"
    ) {
      return "AUTOMATIC";
    } else {
      return "MANUAL";
    }
  }
  return null;
};
