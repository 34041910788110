import { Dialog } from "@mui/material";
import React from "react";

function InstructionsDialogComponent({ instructionDialog, setInstructionDialog }) {
  return (
    <Dialog
      open={instructionDialog}
      maxWidth="sm"
      onClose={() => {
        setInstructionDialog(false);
      }}
    >
      <div style={{ padding: "5px 20px 20px 20px" }}>
        <span
          onClick={() => {
            setInstructionDialog(false);
          }}
          style={{
            fontSize: "25px",
            display: "flex",
            justifyContent: "right",
            cursor: "pointer",
          }}
        >
          x
        </span>
        <h2
          style={{
            color: "#000000",
            fontWeight: 800,
            display: "flex",
            justifyContent: "center",
            marginBottom: "20px",
            marginTop: "0px",
          }}
        >
          Instructions
        </h2>
        <h4
          style={{
            color: "#000000",
            fontWeight: 600,
            padding: "0px 8px !important",
            display: "flex",
            justifyContent: "center",
            marginBottom: "8px",
            marginTop: "5px",
          }}
        >
          Note: "Ensure the cable is not plugged into the vehicle."
        </h4>
        <ol>
          <li>Make Payment or Enter code.</li>
          <li>Start Session.</li>
          <li>Then Plug In. (Connect cable only after starting the session)</li>
        </ol>
      </div>
    </Dialog>
  );
}

export default InstructionsDialogComponent;
