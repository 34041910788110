import { Buffer } from "buffer";

const crypto = require("../library/crypto");

export const decryptText = (text, secretKey, ivParam) => {
  const enc = Buffer.from(text, "base64");
  const IV = Buffer.from(ivParam, "base64");
  const KEY = Buffer.from(secretKey, "base64");
  const authTag = enc.slice(enc.length - 16);
  const encryptedString = enc.slice(0, enc.length - 16);
  const decipher = crypto.createDecipheriv("aes-256-gcm", KEY, IV);
  decipher.setAuthTag(authTag);
  let decryptedString = decipher.update(encryptedString, "base64", "utf8");
  decryptedString += decipher.final("utf8");
  return decryptedString;
};
