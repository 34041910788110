import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { Field, Form } from "react-final-form";
import InTextField from "../../library/TextField/textfield";
import { checkFormValid } from "../../utils/app-utils";

function AuthorizationForm({ handleAuthSubmit, classes, emailError, isEmailExist }) {
  return (
    <>
      <Form onSubmit={(values) => handleAuthSubmit(values)}>
        {({ handleSubmit, values, invalid }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Box>
                <Box>
                  <Typography sx={{ display: "flex", justifyContent: "start" }}>Code</Typography>

                  <Field name="webId" component={InTextField} className={classes.textfield} />
                </Box>
                {isEmailExist && (
                  <Box>
                    <Typography sx={{ display: "flex", justifyContent: "start" }}>Email</Typography>
                    <Field name="email" component={InTextField} className={classes.textfield} />
                  </Box>
                )}

                <Box
                  sx={{
                    justifyContent: "center",
                    textAlign: "center",
                    color: "red",
                    fontSize: "13px",
                    paddingTop: "12px",
                  }}
                >
                  {emailError}
                </Box>

                <div
                  className="col-md-12"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingTop: "5px",
                  }}
                >
                  <Button
                    variant="text"
                    sx={{
                      letterSpacing: "0.00938em",
                      background: "#333333",
                      width: "100%",
                      color: "#fff",

                      "&[disabled]": {
                        opacity: "0.7",
                        pointerEvents: "none",
                        color: "#fff",
                      },
                      "&:hover": {
                        background: "#333333",
                        color: "#fff",
                      },
                    }}
                    onClick={handleSubmit}
                    disabled={checkFormValid(values) ? false : true}
                  >
                    Connect
                  </Button>
                </div>
              </Box>
            </form>
          );
        }}
      </Form>
    </>
  );
}

export default AuthorizationForm;
