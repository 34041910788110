import { AppBar, Paper, Table, TableBody, TableCell, TableContainer, Box } from "@mui/material";
import dayjs from "dayjs";
import { convertSecondsToTime } from "../../utils/app-utils";
import { isEmpty } from "underscore";

const DashboardComponent = (props) => {
  const { prevRecords } = props;

  return (
    <div style={{ padding: "0px 10px" }}>
      <AppBar position="static">
        <Box
          sx={{
            background: "#23B54B",
            padding: "10px",
            display: "flex",
            justifyContent: "center",
            boxShadow: "0px 3px 5px 0px rgb(75 71 75 / 52%)",
            fontSize: "19px",
            letterSpacing: "0.00938em",
          }}
        >
          Previous Sessions
        </Box>
      </AppBar>
      <TableContainer component={Paper} className="material-table-container">
        {!isEmpty(prevRecords) && !isEmpty(prevRecords?.dailySummary) ? (
          <Table>
            {prevRecords?.dailySummary?.map((summary, index) => {
              return (
                <TableBody key={index} sx={{ display: "flex" }}>
                  <TableCell sx={{ width: "25%", display: "flex", justifyContent: "center" }}>
                    {dayjs(summary.date).format("MM-DD-YYYY")}
                  </TableCell>
                  <TableCell sx={{ width: "25%", display: "flex", justifyContent: "center" }}>
                    {summary?.chargingTerminalCode}
                  </TableCell>
                  <TableCell sx={{ width: "25%", display: "flex", justifyContent: "center" }}>
                    {summary.totalDurationInSeconds
                      ? convertSecondsToTime(summary.totalDurationInSeconds)
                      : "-"}
                  </TableCell>
                  <TableCell sx={{ width: "25%", display: "flex", justifyContent: "center" }}>
                    {summary.totalEnergyConsumed
                      ? summary.totalEnergyConsumed + " " + summary.unit
                      : "-"}{" "}
                    {}
                  </TableCell>
                </TableBody>
              );
            })}
          </Table>
        ) : (
          <Box
            sx={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "10%" }}
          >
            No previous Sessions
          </Box>
        )}
      </TableContainer>
    </div>
  );
};

export default DashboardComponent;
