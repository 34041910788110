export const TERMINAL_DETAILS = "TERMINAL_DETAILS";
export const AUTHORISE = "AUTHORISE";
export const COMMAND = "COMMAND";
export const ACCOUNT_ID = "ACCOUNT_ID";
export const LOADER = "LOADER";
export const TOKEN = "TOKEN";
export const CHARGE_STATUS = "CHARGE_STATUS";
export const CHARGE_HISTORY = "CHARGE_HISTORY";
export const CHARGING_SESSION = "CHARGING_SESSION";
export const AUTH_DETAILS = "AUTH_DETAILS";
export const SESSION_STATUS = "SESSION_STATUS";
export const FEEDBACK = "FEEDBACK";
export const CHARGE_STATUS_CHECK = "CHARGE_STATUS_CHECK";
export const INIT_CHARGE_CHECK = "INIT_CHARGE_CHECK";
export const GET_STATION_DETAILS = "GET_STATION_DETAILS";
export const MAKE_TRANSACTION = "MAKE_TRANSACTION";
export const USER_TYPE = "USER_TYPE";
export const STRIPE_PID = "STRIPE_PID";
