import _ from "underscore";
import Cookies from "universal-cookie";
import { authConstants } from "../constants/constants";

class userAuth {
  handleUserAuth(options) {
    const cookies = new Cookies();
    // let domain = new URL(document.location.origin);
    let authKey = new URLSearchParams(document.location.search).get(authConstants.URL_PARAM);
    let refreshKey = new URLSearchParams(document.location.search).get(authConstants.REFRESH_PARAM);
    let accountKey = new URLSearchParams(document.location.search).get(authConstants.ACCOUNT_PARAM);
    if (authKey && accountKey && refreshKey) {
      cookies.set(authConstants.URL_PARAM, authKey, { path: "/" });
      cookies.set(authConstants.REFRESH_PARAM, refreshKey, { path: "/" });
      cookies.set(authConstants.ACCOUNT_PARAM, accountKey, { path: "/" });
    } else {
      authKey = cookies.get(authConstants.URL_PARAM, { path: "/" });
      refreshKey = cookies.get(authConstants.REFRESH_PARAM, { path: "/" });
      accountKey = cookies.get(authConstants.ACCOUNT_PARAM, { path: "/" });
    }
    const authData = cookies.get(authKey, { path: "/" });
    const refreshData = cookies.get(refreshKey, { path: "/" });
    const accountData = cookies.get(accountKey, { path: "/" });
    if (_.isEmpty(authData) || _.isEmpty(accountData) || _.isEmpty(refreshData)) {
      cookies.set(authConstants.HOST_URL, options.appEndpoint, { path: "/" });
      let authKey = cookies.get(authConstants.URL_PARAM, { path: "/" });
      let refreshKey = cookies.get(authConstants.REFRESH_PARAM, { path: "/" });
      let accountKey = cookies.get(authConstants.ACCOUNT_PARAM, { path: "/" });
      cookies.remove(authKey, { path: "/" });
      cookies.remove(refreshKey, { path: "/" });
      cookies.remove(accountKey, { path: "/" });
      let client_details = {
        clientId: options.clientId,
        clientSecret: options.clientSecret,
      };
      cookies.set(authConstants.CLIENT, window.btoa(JSON.stringify(client_details)), { path: "/" });
      window.location.href = options.ssoUrl;
    } else if (!_.isEmpty(authData) && !_.isEmpty(accountData) && !_.isEmpty(refreshData)) {
      let currentTime = new Date().getTime();
      let refreshExpiresIn = cookies.get(authConstants.REFRESH_EXPIRES_IN, { path: "/" });
      if (
        refreshExpiresIn &&
        parseInt(refreshExpiresIn) &&
        currentTime < parseInt(refreshExpiresIn)
      ) {
        const authDataDecoded = window.atob(authData);
        const refreshDataDecoded = window.atob(refreshData);
        const accountDecoded = window.atob(accountData);
        return {
          userData: {
            accessToken: JSON.parse(authDataDecoded),
            refreshToken: JSON.parse(refreshDataDecoded),
          },
          accountId: accountDecoded,
        };
      } else {
        cookies.set(authConstants.HOST_URL, options.appEndpoint, { path: "/" });
        let authKey = cookies.get(authConstants.URL_PARAM, { path: "/" });
        let refreshKey = cookies.get(authConstants.REFRESH_PARAM, { path: "/" });
        let accountKey = cookies.get(authConstants.ACCOUNT_PARAM, { path: "/" });
        cookies.remove(authKey, { path: "/" });
        cookies.remove(refreshKey, { path: "/" });
        cookies.remove(accountKey, { path: "/" });
        let client_details = {
          clientId: options.clientId,
          clientSecret: options.clientSecret,
        };
        cookies.set(authConstants.CLIENT, window.btoa(JSON.stringify(client_details)), {
          path: "/",
        });
        window.location.href = options.ssoUrl;
      }
    }
  }

  handleUserLogout(options) {
    const cookies = new Cookies();
    cookies.set(authConstants.HOST_URL, options.appEndpoint, { path: "/" });
    let authKey = cookies.get(authConstants.URL_PARAM, { path: "/" });
    let refreshKey = cookies.get(authConstants.REFRESH_PARAM, { path: "/" });
    let accountKey = cookies.get(authConstants.ACCOUNT_PARAM, { path: "/" });
    cookies.remove(authKey, { path: "/" });
    cookies.remove(refreshKey, { path: "/" });
    cookies.remove(accountKey, { path: "/" });
    cookies.remove(authConstants.URL_PARAM, { path: "/" });
    let client_details = {
      clientId: options.clientId,
      clientSecret: options.clientSecret,
    };

    cookies.set(authConstants.CLIENT, window.btoa(JSON.stringify(client_details)), { path: "/" });
    window.location.href = `${options.ssoUrl}`;
  }
}

const AuthHandler = new userAuth();
export default AuthHandler;
