import axios from "axios";
import Cookies from "universal-cookie";
import { authConstants, grantConst } from "../constants/constants";

class userToken {
  handleRefreshToken(options) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          options.refreshApiUrl,
          {
            clientId: options.clientId,
            clientSecret: options.clientSecret,
            refreshToken: options.userDetails?.refreshToken,
            grantType: grantConst.refresh_token,
          },
          { headers: { accountId: options.accountId } }
        )
        .then((response) => {
          const cookies = new Cookies();
          let authKey = cookies.get(authConstants.URL_PARAM, { path: "/" });
          let refreshKey = cookies.get(authConstants.REFRESH_PARAM, { path: "/" });
          const access_token_encoded = window.btoa(
            JSON.stringify(response?.data?.data?.accessToken)
          );
          const refresh_token_encoded = window.btoa(
            JSON.stringify(response?.data?.data?.refreshToken)
          );

          cookies.set(authKey, access_token_encoded, { path: "/" });
          cookies.set(refreshKey, refresh_token_encoded, { path: "/" });
          return resolve(response);
        })
        .catch((err) => {
          const cookies = new Cookies();
          cookies.remove(authConstants.URL_PARAM, { path: "/" });
          return reject(err);
        });
    });
  }
}

const TokenHandler = new userToken();
export default TokenHandler;
