import _ from "underscore";
import { statusCodes } from "../constants/constants";

export const successCheck = (response) => {
   let returnVal = false;
   if (
      response &&
      (response?.status === statusCodes.successCode1 ||
         response?.status === statusCodes.successCode2 ||
         response?.status === statusCodes.successCode0)
   ) {
      returnVal = true;
   }
   return returnVal;
};

export const failureCheck = (response) => {
   let returnVal = false;
   if (
      !_.isEmpty(response) &&
      (response?.status !== statusCodes.successCode1 ||
         response?.status !== statusCodes.successCode2 ||
         response?.status !== statusCodes.successCode0)
   ) {
      returnVal = true;
   }
   return returnVal;
};
