import * as actionTypes from "./types";

const initialState = {
  terminalDetails: {},
  authoriseResponse: null,
  commandResult: {},
  token: null,
  chargeStatus: null,
  chargeHistory: null,
  loader: false,
  chargingSession: null,
  authDetails: {},
  sessionStatus: null,
  initLoad: false,
  feedback: {},
  isChargeStatusCheck: false,
  initChargeCheck: null,
  stationDetails: {},
  transactionInitData: null,
  chargeUserType: null,
  stripeId: null,
};

const authorizationReducer = (state = initialState, action) => {
  switch (action.type) {
    // For type of charges
    case actionTypes.TERMINAL_DETAILS:
      return {
        ...state,
        terminalDetails: action.payload,
      };
    case actionTypes.AUTHORISE:
      return {
        ...state,
        authoriseResponse: action.payload,
      };
    case actionTypes.COMMAND:
      return {
        ...state,
        commandResult: action.payload,
      };
    case actionTypes.ACCOUNT_ID:
      return { ...state, userAccountId: action.payload };
    case actionTypes.TOKEN:
      return {
        ...state,
        token: action.payload && action.payload.code ? action.payload.data : action.payload,
      };
    case actionTypes.CHARGE_STATUS:
      return { ...state, chargeStatus: action.payload };
    case actionTypes.CHARGE_HISTORY:
      return { ...state, chargeHistory: action.payload };
    case actionTypes.LOADER:
      return { ...state, loader: action.payload };
    case actionTypes.CHARGING_SESSION:
      return { ...state, chargingSession: action.payload };
    case actionTypes.AUTH_DETAILS:
      return { ...state, authDetails: action.payload };
    case actionTypes.SESSION_STATUS:
      return { ...state, sessionStatus: action.payload };
    case actionTypes.FEEDBACK:
      return { ...state, feedback: action.payload };
    case actionTypes.CHARGE_STATUS_CHECK:
      return { ...state, isChargeStatusCheck: action.payload };
    case actionTypes.INIT_CHARGE_CHECK:
      return { ...state, initChargeCheck: action.payload };
    case actionTypes.GET_STATION_DETAILS:
      return {
        ...state,
        stationDetails: action.payload,
      };
    case actionTypes.MAKE_TRANSACTION:
      return { ...state, transactionInitData: action.payload };
    case actionTypes.USER_TYPE:
      return { ...state, chargeUserType: action.payload };
    case actionTypes.STRIPE_PID:
      return { ...state, stripeId: action.payload };

    default:
      return state;
  }
};

export default authorizationReducer;
