import { requestTypes } from "../constants/constants";
import AxiosHandler from "../network/axios";

class networkManager {
  handleRequest(url, postData, options, store) {
    switch (options.requestType) {
      case requestTypes.axios:
        return AxiosHandler.handleRequest(url, postData, options, store);
      default:
        return AxiosHandler.handleRequest(url, postData, options, store);
    }
  }
}

const NetworkManager = new networkManager();
export default NetworkManager;
