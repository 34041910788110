import DashboardComponent from "./components/dashboard.component";
import { makeStyles } from "@mui/styles";
import useMediaQuery from "../helpers/media-query-handler";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  checkChargeSession,
  checkInitCharging,
  fetchChargeHistory,
  fetchChargeStatus,
  setLoader,
  setStoreData,
  triggerCommnad,
} from "../services/actions";
import { isEmpty } from "underscore";
import dayjs from "dayjs";
import { failureCheck, successCheck } from "../utils/api-response-check";
import { errorToaster } from "../utils/pop-up";
import { chargingStates, sessionStatus } from "../constants/constants";
import ChargingComponent from "./components/charging.component";
import { SESSION_STATUS } from "../services/types";
import DialogComponent from "./components/dialog-component";
import { commandRequestUtil } from "../utils/request-utils";

const useStyles = makeStyles((theme) => ({
  main_div: { padding: "2rem" },
  main_div_768: { marginTop: "0rem" },
}));

const DashboardContainer = () => {
  const classes = useStyles();

  const { authorize } = useSelector((state) => state);
  const [chargeStatus, setChargeStatus] = useState(chargingStates.STOP);
  const [openDialog, setOpenDialog] = useState({
    preparing: false,
    finish: false,
    error: false,
    payment: false,
    statusAlert: false,
    transactionError: false,
    chargingError: false,
  });
  const [currentStatus, setCurrentStatus] = useState({});
  const [prevStatus, setPrevStatus] = useState([]);
  const isMob = useMediaQuery("(max-width: 768px)");
  const isTab = useMediaQuery("(max-width: 945px)");

  const is768 = isMob || isTab;
  const dispatch = useDispatch();

  let currentDate = new Date();
  let firstday = new Date(new Date().setDate(new Date().getDate() - 30));

  useEffect(() => {
    if (authorize.chargeUserType === "GUEST" || authorize.isChargeStatusCheck) {
      makeSessionCall();
    } else {
      if (authorize.chargeUserType !== "GUEST") {
        dispatch(
          checkInitCharging({
            ...authorize.authDetails,
            terminalCode: authorize.terminalDetails?.terminalCode,
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isEmpty(authorize.initChargeCheck)) {
      if (successCheck(authorize.initChargeCheck)) {
        dispatch(setStoreData(SESSION_STATUS, sessionStatus.CHARGE_INITIATE));
        setChargeStatus(chargingStates.STOP);
      } else {
        if (authorize.initChargeCheck.status === 10544) {
          setOpenDialog({
            preparing: false,
            finish: false,
            error: false,
            payment: false,
            statusAlert: false,
            transactionError: false,
            chargingError: true,
          });
          dispatch(setStoreData(SESSION_STATUS, ""));
        } else if (authorize.initChargeCheck.status === 10545) {
          makeSessionCall();
        }
      }
    }
    //eslint-disable-next-line
  }, [authorize.initChargeCheck]);

  useEffect(() => {
    // cheking CHarging session API response is success
    if (!isEmpty(authorize.chargingSession) && authorize.chargingSession?.status === 0) {
      if (authorize.chargingSession?.data?.chargingStatus === "REMOTE_START_REQUESTED") {
        // SCENARIO: REMOTE_START_REQUESTED - App is in initial state, Calling Status API to check charging started or still Preparing State
        dispatch(setStoreData(SESSION_STATUS, sessionStatus.CHARGE_INITIATE));
        makeStatusCall();
        dispatch(setLoader(false));
      } else if (
        authorize.chargingSession?.data?.chargingStatus === "CHARGING_STARTED" ||
        authorize.chargingSession?.data?.chargingStatus === "REMOTE_STOP_REQUESTED"
      ) {
        // SCENARIO:CHARGING_STARTED - if status api is not called till now, then calling Status API
        if (isEmpty(authorize.chargeStatus)) {
          makeStatusCall();
        }
        // Calling History API, Reseting APP states
        setOpenDialog({
          ...openDialog,
          preparing: false,
          statusAlert: false,
        });
        dispatch(setStoreData(SESSION_STATUS, sessionStatus.CHARGING_ONGOING));
        setChargeStatus(chargingStates.START);
        if (isEmpty(prevStatus)) {
          getChargeHistory();
        }
        dispatch(setLoader(false));
      } else {
        //SCENARIO- no charging inprogress
        setOpenDialog({
          ...openDialog,
          preparing: false,
          statusAlert: false,
        });
        setChargeStatus(chargingStates.STOP);
        if (
          chargeStatus === chargingStates.START &&
          authorize.sessionStatus === sessionStatus.CHARGING_ONGOING
        ) {
          dispatch(setStoreData(SESSION_STATUS, sessionStatus.CHARGING_STOPED));
        } else {
          if (!authorize.isChargeStatusCheck) {
            // not for charging status check
            dispatch(setStoreData(SESSION_STATUS, sessionStatus.CHARGE_INITIATE));
          } else if (
            authorize.isChargeStatusCheck ||
            ((authorize.chargeUserType === "GUEST" || authorize.chargeUserType === "PREMIUM") &&
              isEmpty(authorize.authoriseResponse))
          ) {
            dispatch(setStoreData(SESSION_STATUS, sessionStatus.CHARGING_STOPED));
          } else {
            dispatch(setStoreData(SESSION_STATUS, sessionStatus.CHARGE_INITIATE));
          }
        }
        dispatch(setLoader(false));
      }
      setOpenDialog({
        ...openDialog,
        error: false,
      });
    } else if (!isEmpty(authorize.chargingSession) && authorize.chargingSession?.status !== 0) {
      dispatch(setLoader(false));
      setOpenDialog({
        ...openDialog,
        preparing: false,
        finish: false,
        error: false,
        statusAlert: false,
      });
      if (authorize.chargingSession?.status === 10513) {
      } else {
        dispatch(setStoreData(SESSION_STATUS, sessionStatus.CHARGE_INITIATE));
      }
    }

    //eslint-disable-next-line
  }, [authorize.chargingSession]);

  useEffect(() => {
    if (!isEmpty(authorize.chargeStatus) && authorize.chargeStatus?.status === 0) {
      if (authorize.chargeStatus?.data?.terminalStatus !== "Unavailable") {
        // onl if terminal is unavailable
        if (authorize.chargeStatus?.data?.status === "Finishing") {
          if (authorize.chargingSession?.data?.chargingStatus === "REMOTE_STOP_REQUESTED") {
            // finishing popup if Session is in "REMOTE_STOP_REQUESTED"
            setOpenDialog({
              preparing: false,
              finish: true,
              error: false,
              payment: false,
              statusAlert: false,
              transactionError: false,
              chargingError: false,
            });
            dispatch(setLoader(false));
          } else if (authorize.chargingSession?.data?.chargingStatus === "CHARGING_STARTED") {
            // if Session is in "CHARGING_STARTED", triger sessionCall
            const timeInterval = 5000;
            let flag = true;
            const interval = setInterval(() => {
              if (flag) {
                makeSessionCall();
                flag = false;
              }
            }, timeInterval);
            return () => clearInterval(interval);
          } else {
            // closde finishDialog and update sesstion status is Charging stoped if CHARGING_STOPPED
            setOpenDialog({
              ...openDialog,
              finish: false,
            });
            if (authorize.chargingSession?.data?.chargingStatus === "CHARGING_STOPPED") {
              dispatch(setStoreData(SESSION_STATUS, sessionStatus.CHARGING_STOPED));
              dispatch(setLoader(false));
            }
          }
          setOpenDialog({
            ...openDialog,
            preparing: false,
            statusAlert: false,
          });
        } else if (
          authorize.chargeStatus?.data?.status === "Available" &&
          (authorize.chargingSession?.data?.chargingStatus === "CHARGING_STARTED" ||
            authorize.chargingSession?.data?.chargingStatus === "REMOTE_STOP_REQUESTED")
        ) {
          const timeInterval = 5000;
          let flag = true;
          const interval = setInterval(() => {
            if (flag) {
              makeSessionCall();
              flag = false;
            }
          }, timeInterval);
          return () => clearInterval(interval);
        } else if (
          authorize.chargeStatus?.data?.status === "Charging" ||
          authorize.chargingSession?.data?.chargingStatus === "CHARGING_STARTED" ||
          authorize.chargingSession?.data?.chargingStatus === "REMOTE_STOP_REQUESTED"
        ) {
          dispatch(setLoader(false));
          setOpenDialog({
            ...openDialog,
            preparing: false,
            statusAlert: false,
          });
          setChargeStatus(chargingStates.START);
          if (isEmpty(prevStatus)) {
            getChargeHistory();
          }
          if (authorize.chargingSession?.data?.chargingStatus === "REMOTE_START_REQUESTED") {
            makeSessionCall();
          }
        } else if (authorize.chargeStatus?.data?.status === "Preparing") {
          dispatch(setLoader(false));
          setOpenDialog({
            preparing: true,
            finish: false,
            error: false,
            payment: false,
            statusAlert: false,
            transactionError: false,
            chargingError: false,
          });
          const timeInterval = 20000;
          const interval = setInterval(() => {
            makeStatusCall();
          }, timeInterval);
          return () => clearInterval(interval);
        } else {
          dispatch(setLoader(false));

          setOpenDialog({
            ...openDialog,
            preparing: false,
            finish: false,
            statusAlert: false,
          });
          const timeInterval = 10000;
          const interval = setInterval(() => {
            makeStatusCall();
          }, timeInterval);
          return () => clearInterval(interval);
        }
      } else {
        setOpenDialog({
          preparing: false,
          finish: false,
          error: false,
          payment: false,
          statusAlert: true,
          transactionError: false,
          chargingError: false,
        });
        const timeInterval = 20000;
        const interval = setInterval(() => {
          makeStatusCall();
        }, timeInterval);
        return () => clearInterval(interval);
      }

      setCurrentStatus(authorize.chargeStatus.data); // to show the metervalue of while charging is inprogress
    } else if (
      !isEmpty(authorize.chargeStatus) &&
      authorize.chargeStatus.status !== 0 &&
      isEmpty(authorize.chargeStatus?.data)
    ) {
      // SCENARIO: No charging inprogress
      setOpenDialog({
        preparing: false,
        finish: false,
        statusAlert: false,
      });
      if (
        chargeStatus === chargingStates.START &&
        authorize.sessionStatus === sessionStatus.CHARGING_ONGOING
      ) {
        let isTrigger = true;
        const timeInterval = 10000;
        const interval = setInterval(() => {
          if (isTrigger && authorize.chargingSession?.data?.chargingStatus !== "CHARGING_STOPPED") {
            makeSessionCall();
          }
          isTrigger = false;
        }, timeInterval);
        return () => clearInterval(interval);
      } else if (
        chargeStatus === chargingStates.STOP &&
        authorize.sessionStatus === sessionStatus.CHARGE_INITIATE
      ) {
        setChargeStatus(chargingStates.STOP);
        dispatch(setStoreData(SESSION_STATUS, sessionStatus.CHARGE_INITIATE));
      }
      dispatch(setLoader(false));
    }

    //eslint-disable-next-line
  }, [authorize.chargeStatus, authorize.sessionStatus]);

  useEffect(() => {
    // status API is calling in every 30 sec when charging is started.
    const timeInterval = 30000;
    const interval = setInterval(() => {
      if (chargeStatus === chargingStates.START && timeInterval) {
        makeStatusCall();
      }
    }, timeInterval);
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [chargeStatus]);

  useEffect(() => {
    if (!isEmpty(authorize.chargeHistory)) {
      if (successCheck(authorize.chargeHistory)) {
        setPrevStatus(authorize.chargeHistory?.data);
      }
    }
  }, [authorize.chargeHistory]);

  useEffect(() => {
    if (!isEmpty(authorize.commandResult)) {
      if (successCheck(authorize.commandResult)) {
        let isTrigger = true;
        const timeInterval = chargeStatus === chargingStates.STOP ? 10000 : 15000;
        const interval = setInterval(() => {
          if (isTrigger && authorize.sessionStatus !== sessionStatus.CHARGING_STOPED) {
            makeSessionCall();
          }
          isTrigger = false;
          dispatch(setLoader(false));
        }, timeInterval);

        return () => {
          clearInterval(interval);
          // clearInterval(transactionInterval);
        };
      } else if (failureCheck(authorize.commandResult)) {
        dispatch(setLoader(false));
        errorToaster(authorize.commandResult.message);
      }
    }
    //eslint-disable-next-line
  }, [authorize.commandResult]);

  const handleChargeCommand = (command) => {
    let params = commandRequestUtil(
      command.toString(),
      authorize,
      authorize.authDetails.sessionId
        ? authorize.authDetails.sessionId
        : authorize.authDetails.reservationId,
      authorize.authDetails.userType
    );
    dispatch(setLoader(true));
    dispatch(triggerCommnad(params));
  };

  const makeSessionCall = () => {
    dispatch(
      checkChargeSession({
        userType: authorize.authDetails.userType,
        reservationId: authorize.authDetails.sessionId
          ? authorize.authDetails.sessionId
          : authorize.authDetails.reservationId,
        terminalCode: authorize.terminalDetails?.terminalCode,
      })
    );
  };

  const makeStatusCall = () => {
    dispatch(
      fetchChargeStatus({
        terminalCode: authorize.terminalDetails?.terminalCode,
        reservationId: authorize.authDetails.sessionId
          ? authorize.authDetails.sessionId
          : authorize.authDetails.reservationId,
        userType: authorize.authDetails.userType,
      })
    );
  };

  const getChargeHistory = () => {
    if (authorize.chargeUserType !== "GUEST" && authorize.chargeUserType !== "PREMIUM") {
      dispatch(
        fetchChargeHistory({
          id: authorize.authDetails.reservationId,
          userType: authorize.authDetails.userType,
          startDate: dayjs(firstday).format("YYYY-MM-DD"),
          endDate: dayjs(currentDate).format("YYYY-MM-DD"),
        })
      );
    }
  };

  return (
    <div className={is768 ? classes.main_div_768 : classes.main_div}>
      <DialogComponent
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        dispatch={dispatch}
        authorize={authorize}
      />
      <div
        style={
          is768
            ? {
                marginTop: "0rem",
                width: "auto",
                display: "flex",
                flexDirection: "column",
              }
            : {
                width: "auto",
                display: "flex",
                flexDirection: "column",
              }
        }
      >
        {!isEmpty(authorize.sessionStatus) && (
          <>
            <ChargingComponent
              handleChargeCommand={handleChargeCommand}
              is768={is768}
              currentRecord={currentStatus}
              authorize={authorize}
              chargeData={authorize.chargingSession?.data}
              dialogState={openDialog}
              setDialogState={(val) => {
                setOpenDialog({ ...val });
              }}
            />
            {authorize.sessionStatus !== sessionStatus.CHARGE_INITIATE &&
              authorize.chargeUserType !== "GUEST" &&
              authorize.chargeUserType !== "PREMIUM" && (
                <div style={{ marginTop: is768 ? "2rem" : "1rem" }}>
                  <DashboardComponent
                    prevRecords={prevStatus}
                    currentRecord={currentStatus}
                    authorize={authorize}
                    is768={is768}
                    chargeStatus={chargeStatus}
                  />
                </div>
              )}
          </>
        )}
      </div>
    </div>
  );
};

export default DashboardContainer;
