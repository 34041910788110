import React from "react";
// import "./style.scss";

export default function InTextField({
  input,
  maxLength,
  minLength,
  meta,
  label,
  isDisable,
  className,
  handleChange,
  icon,
  imgClassName,
  value,
  handleOnClick,
  style,
}) {
  return (
    <div>
      <input
        {...input}
        type={input && input.type}
        maxLength={maxLength}
        minLength={minLength}
        className={className}
        style={style}
        placeholder={label}
        disabled={isDisable}
        onChange={(e) => {
          input && input.onChange(e.target.value);
          handleChange && handleChange(e);
        }}
        value={value ? value : input ? input.value : ""}
        onClick={handleOnClick}
      />
      {/* {icon ? (
        <span style={{ marginLeft: -30 }}>
          <img src={icon} alt="" className={imgClassName} />
        </span>
      ) : null} */}
      <div className="validation-error">
        {meta && meta.error && meta.touched && <span>{meta.error}</span>}
      </div>
    </div>
  );
}
