import AuthHandler from "../handler/auth-handler";
import TokenHandler from "../handler/token-handler";

class userService {
  handleAuth(options) {
    return AuthHandler.handleUserAuth(options);
  }

  handleRefreshToken(options) {
    return TokenHandler.handleRefreshToken(options);
  }

  handleAppRedirection(auth) {
    AuthHandler.handleAppRedirection(auth);
  }

  handleUserLogout(options) {
    AuthHandler.handleUserLogout(options);
  }
}

const UserService = new userService();
export default UserService;
