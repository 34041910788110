import React from "react";
import "./style.scss";

export default function Button({ label, onClick, type, className, style, disabled, id }) {
  return (
    <button
      onClick={onClick}
      type={type}
      className={className ? className : "button-style"}
      style={style}
      disabled={disabled}
      aria-describedby={id}
    >
      <span>{label}</span>
    </button>
  );
}
