export const statusCodes = {
  successCode1: 200,
  successCode2: 201,
  successCode0: 0,
  notModified: 304,
  badRequest: 400,
  unAuthorized: 401,
  tokenFailed: "TOKEN_FAILED",
  TOKEN_ACTION: "TOKEN",
};

export const userConst = {
  guest: "GUEST",
  client: "sso_login_service",
};

export const cacheConstants = {
  ERROR: "Something went wrong",
  SUCCESSMSG: "success",
  LAST_MODIFIED: "last-modified",
  REQ_CANCELLED: "REQUEST_CANCELLED",
  USAGE_AUDIT: "USAGE_AUDIT",
  IF_MODIFIED_SINCE: "If-Modified-Since",
};

export const cacheOptions = {
  cache: "False",
  cacheKey: "token",
};

export const requestTypes = {
  axios: "AXIOS",
};

export const grantTypeConst = {
  password: "password",
};