import Button from "../../library/Button/button";
import { makeStyles } from "@mui/styles";
import { chargingStates, sessionStatus } from "../../constants/constants";
import { Typography } from "@mui/material";
import ConfirmationDialog from "../../library/ConfirmationDialog/confimationDialog";
import { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { dialogueConfig, transactionErrorConfig } from "../../utils/dialog-config-utils";
import { convertSecondsToTime, priceUnit } from "../../utils/app-utils";
import ReactStars from "react-rating-stars-component";
import TextArea from "../../library/TextArea/textarea";
import { useDispatch } from "react-redux";
import { postFeedBack } from "../../services";
import { chargeSessionStyles } from "../../utils/pages-styles";

const useStyles = makeStyles((theme) => ({
  startButton: (props) => props.startButton,
  stopButton: (props) => props.stopButton,
  startButton_768: (props) => props.startButton_768,
  stopButton_768: (props) => props.stopButton_768,
  textArea: (props) => props.textArea,
  saveButton: (props) => props.saveButton,
  thankyou_div_style: (props) => props.thankyou_div_style,
  command_btn_div: (props) => props.command_btn_div,
  session_end_div: (props) => props.session_end_div,
}));

const ChargingComponent = (props) => {
  const {
    handleChargeCommand,
    is768,
    currentRecord,
    authorize,
    chargeData,
  } = props;
  const classes = useStyles(chargeSessionStyles());
  const [openDialogue, setOpenDialogue] = useState(false);
  const [value, setValue] = useState("");
  const [Feedback, setFeedback] = useState("");
  const dispatch = useDispatch();
  const [isFeedbackSuccess, setIsFeedbackSuccess] = useState(false);

  const ratingChanged = (newRating) => {
    setValue(newRating);
    if (newRating) {
      setFeedback("");
    }
  };

  const handleSubmit = () => {
    let params = {
      feedbackComment: Feedback ? Feedback : "",
      feedbackScore: value,
      sessionId: chargeData?.sessionId,
    };
    dispatch(postFeedBack(params));
    setIsFeedbackSuccess(true);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      

      <ConfirmationDialog
        open={openDialogue}
        dialogueConfig={dialogueConfig}
        handleClose={() => setOpenDialogue(false)}
        handleDialogueCancel={() => setOpenDialogue(false)}
        handleDialogueSubmit={() => {
          handleChargeCommand(chargingStates.STOP);
          setOpenDialogue(false);
        }}
      />

      {authorize.sessionStatus === sessionStatus.CHARGE_INITIATE && (
        <>
          <Typography
            sx={{
              fontWeight: "600",
              fontSize: "23px",
              padding: is768 ? "10% 0% 2% 0%" : "1% 0% 0% 0%",
            }}
          >
            {authorize?.authDetails?.terminalName
              ? authorize?.authDetails?.terminalName
              : authorize?.terminalDetails?.terminalCode}
          </Typography>
          <img
            src={
              "https://emaestro-svp-cms.s3.amazonaws.com/assets/a59123ac-085a-4992-a013-5e1be46d4a52.png"
            }
            style={{ width: is768 ? "15%" : "5%" }}
            alt=""
          />
          <div
            className={classes.command_btn_div}
            style={{
              marginTop: is768 ? "6%" : "1%",
            }}
          >
            <Button
              label="Start"
              className={is768 ? classes.startButton_768 : classes.startButton}
              onClick={() => handleChargeCommand(chargingStates.START)}
            />
          </div>
          <Typography
            sx={{
              fontWeight: "900",
              fontSize: "20px",
              paddingTop: "5px",
            }}
          >
            Session
          </Typography>
        </>
      )}

      {authorize.sessionStatus === sessionStatus.CHARGING_ONGOING && (
        <>
          <img
            src={
              "https://acsia-svp-cms.s3.ap-south-1.amazonaws.com/assets/4096c145-0fe1-41d1-a9e5-2822cd7d6287.gif"
            }
            style={is768 ? { width: "55%", margin: "0% 0%" } : { width: "30%", margin: "0% 0%" }}
            alt=""
          />
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: is768 ? "28px" : "25px",
              color: "#009934",
            }}
          >
            {currentRecord?.meterValue && currentRecord?.meterValue === "0"
              ? "0.000"
              : currentRecord?.meterValue && currentRecord?.meterValue !== "0"
              ? currentRecord?.meterValue
              : "0.000"}{" "}
            {chargeData?.unit}
          </Typography>
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: "18px",
              paddingTop: is768 ? "7px" : "2px",
            }}
          >
            Energy Delivered
          </Typography>
          <div
            className={classes.command_btn_div}
            style={{
              marginTop: is768 ? "6%" : "2%",
            }}
          >
            <Button
              label="Stop"
              className={is768 ? classes.stopButton_768 : classes.stopButton}
              onClick={() => setOpenDialogue(true)}
            />
          </div>
        </>
      )}

      {authorize.sessionStatus === sessionStatus.CHARGING_STOPED && (
        <>
          <div className={classes.session_end_div}>
            {chargeData?.paymentStatus === "SUCCESS" && (
              <Box sx={{ marginTop: "20px" }}>
                <Typography sx={{ fontSize: "25px", fontWeight: 600, color: "#333333" }}>
                  Transaction Completed
                </Typography>
              </Box>
            )}
            <Typography
              sx={{
                fontWeight: "600",
                fontSize: is768 ? "23px" : "22px",
                padding: is768 ? "10% 0% 5% 0%" : "10% 0% 2% 0%",
              }}
            >
              Session Ended
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                width: is768 ? "14rem" : "16rem",
                height: is768 ? "7rem" : "8rem",
                border: "#d3d3d3",
                boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
                borderRadius: "5px",
                marginBottom: "15px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "600",
                    fontSize: "18px",
                  }}
                >
                  Session Cost
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",

                  alignItems: "center",
                  paddingTop: "5px",
                }}
              >
                <Box
                  sx={{
                    width: "40%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={
                      "https://emaestro-svp-cms.s3.amazonaws.com/assets/8d91be65-fb8f-428c-beac-75e397a429a7.png"
                    }
                    style={{ width: is768 ? "40%" : "35%" }}
                    alt=""
                  />
                </Box>
                <Box sx={{ width: "60%" }}>
                  <Typography
                    sx={{
                      fontWeight: "900",
                      fontSize: is768 ? "25px" : "22px",
                      color: "#009934",
                    }}
                  >
                    {chargeData?.amount
                      ? priceUnit(chargeData?.priceUnit) + chargeData?.amount
                      : "0.000"}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                width: is768 ? "14rem" : "16rem",
                height: is768 ? "7rem" : "8rem",
                border: "#d3d3d3",
                boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
                borderRadius: "5px",
                marginBottom: "15px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "600",
                    fontSize: "18px",
                  }}
                >
                  Time Spent
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",

                  alignItems: "center",
                  paddingTop: "5px",
                }}
              >
                <Box
                  sx={{
                    width: "40%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={
                      "https://emaestro-svp-cms.s3.amazonaws.com/assets/eec6a1f6-085a-46ad-96c5-b0c2169ebe99.png"
                    }
                    style={{ width: is768 ? "40%" : "35%" }}
                    alt=""
                  />
                </Box>
                <Box sx={{ width: "60%" }}>
                  <Typography
                    sx={{
                      fontWeight: "900",
                      fontSize: is768 ? "25px" : "22px",
                      color: "#009934",
                    }}
                  >
                    {chargeData?.durationInSeconds
                      ? convertSecondsToTime(chargeData?.durationInSeconds)
                      : "-"}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                width: is768 ? "14rem" : "16rem",
                height: is768 ? "7rem" : "8rem",
                border: "#d3d3d3",
                boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
                borderRadius: "5px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "600",
                    fontSize: "18px",
                  }}
                >
                  Energy Delivered
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",

                  alignItems: "center",
                  paddingTop: "5px",
                }}
              >
                <Box
                  sx={{
                    width: "40%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={
                      "https://emaestro-svp-cms.s3.amazonaws.com/assets/2e32e3bf-ad76-4e5d-97c6-7b8e600538c3.png"
                    }
                    style={{ width: is768 ? "40%" : "35%" }}
                    alt=""
                  />
                </Box>
                <Box sx={{ width: "60%" }}>
                  <Typography
                    sx={{
                      fontWeight: "900",
                      fontSize: is768 ? "25px" : "22px",
                      color: "#009934",
                    }}
                  >
                    {chargeData?.energyConsumed
                      ? chargeData?.energyConsumed
                      : currentRecord?.meterValue}{" "}
                    {chargeData?.unit}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </div>
          {!isFeedbackSuccess ? (
            <>
              <Typography
                sx={{
                  fontWeight: "600",
                  fontSize: is768 ? "20px" : "20px",
                  paddingTop: "15px",
                }}
              >
                Rate Charging Experience
              </Typography>
              <div style={{ marginBottom: "10px" }}>
                <ReactStars
                  count={5}
                  onChange={ratingChanged}
                  size={is768 ? 40 : 50}
                  activeColor="#009934"
                  color="#D5D5D5"
                />
              </div>
              {value && (
                <>
                  <div style={{ padding: "0px 24px" }}>
                    <TextArea
                      type="textArea"
                      handleChange={(e) => setFeedback(e.target.value)}
                      textareaValue={Feedback}
                      className={classes.textArea}
                      placeholder={"Feedback(Optional)"}
                    />
                  </div>
                  <div className="flex-wrap mb-3 mr-3 ml-40" style={{ marginLeft: 20 }}>
                    <Button
                      type="button"
                      label={"Submit"}
                      className={classes.saveButton}
                      onClick={() => handleSubmit()}
                      disabled={value ? false : true}
                    />
                  </div>
                </>
              )}
            </>
          ) : (
            <div className={classes.thankyou_div_style}>Thank You</div>
          )}
        </>
      )}
    </div>
  );
};

export default ChargingComponent;
