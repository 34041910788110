import axios from "axios";
import _ from "underscore";
import cacheManager from "../service/cache-manager";
import { grantTypeConst, statusCodes } from "../constants/constants";
import UserHandler from "@userService";

let isRefreshing = false;
let refreshSubscribers = [];

class RequestHandler {
  handleRequest(path, reqInit, options, store) {
    //  Request interceptor
    const instance = axios.create();
    instance.defaults.baseURL = path;
    instance.defaults.withCredentials = false;
    if (!_.isEmpty(options.userDetails) && !options.isTokenCall) {
      instance.defaults.headers.common["Authorization"] =
        "Bearer " + options.userDetails?.accessToken;
    }

    if (!_.isEmpty(options.accountId)) {
      instance.defaults.headers.common["accountId"] = options.accountId;
    }

    if (options.cacheKey) {
      let pageData = cacheManager.getCachedData(options.cacheKey);
      if (pageData && store) {
        store.dispatch({
          type: options.actionType,
          payload: pageData?.responseData?.code
            ? pageData?.responseData
            : { code: options.cacheKey, data: pageData?.responseData },
        });
      }
      if (pageData && pageData.headers) {
        instance.defaults.headers.common["If-Modified-Since"] = pageData.headers;
        instance.defaults.headers.common["Cache"] = options.cache;
      }else{
        instance.defaults.headers.common["Cache-Control"] = "no-cache, no-store, must-revalidate";
      }
    }

    //Response interceptor
    instance.interceptors.response.use(
      (response) => {
        return response;
      },
      async function (error) {
        const originalRequest = error.config;
        if (error?.response?.status === statusCodes.unAuthorized) {
          if (reqInit.data && reqInit.data.grantType === grantTypeConst.password) {
            return error?.response;
          }
          if (!isRefreshing) {
            isRefreshing = true;
            return new Promise(function (resolve, reject) {
              // to get access token using refresh token
              let reqParams = {
                clientSecret: options.clientSecret,
                clientId: options.clientId,
                userDetails: options.userDetails,
                accountId: options.accountId,
                refreshApiUrl: options.refreshApiUrl,
                token: options.userDetails?.refreshToken,
              };
              UserHandler.handleRefreshToken(reqParams)
                .then((response) => {
                  isRefreshing = false;
                  originalRequest.headers["Authorization"] =
                    "Bearer " + response?.data?.data?.accessToken;
                  resolve(axios(originalRequest));
                  onRrefreshed(response);
                  store.dispatch({
                    type: statusCodes.TOKEN_ACTION,
                    payload: response?.data?.data,
                  });
                  return response;
                })
                .catch((err) => {
                  isRefreshing = false;
                  let error = {
                    code: statusCodes.tokenFailed,
                  };
                  return reject(error);
                });
            });
          }

          const retryOrigReq = new Promise((resolve, reject) => {
            subscribeTokenRefresh((token) => {
              // replace the expired token and retry
              originalRequest.headers["Authorization"] = "Bearer " + token;
              resolve(axios(originalRequest));
            });
          });
          return retryOrigReq;
        } else if (error?.response?.status === statusCodes.notModified) {
          return Promise.reject(error);
        } else {
          return Promise.reject(error);
        }
      }
    );

    const subscribeTokenRefresh = (cb) => {
      refreshSubscribers.push(cb);
    };

    const onRrefreshed = (tokenResponse) => {
      refreshSubscribers.map((cb) => cb(tokenResponse?.data?.data?.accessToken));
      refreshSubscribers = [];
    };

    const init = Object.assign(reqInit, {});

    return instance(path, init)
      .then((res) => {
        if (options.cache) {
          return cacheManager.handleResponse(res, options);
        } else {
          return res;
        }
      })
      .catch((error) => {
        return error;
      });
  }
}

const AxiosHandler = new RequestHandler();
export default AxiosHandler;
