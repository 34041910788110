import { PaymentElement, LinkAuthenticationElement } from "@stripe/react-stripe-js";
import { useEffect, useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { isEmpty } from "underscore";
import { paymentInitateMsgs } from "../../utils/failure-message-utils";
import { errorToaster } from "../../utils/pop-up";
import { setLoader } from "../../services";
import { failureCheck } from "../../utils/api-response-check";
import { useDispatch } from "react-redux";

export default function StripeForm({
  initiateTransaction,
  authorize,
  makeSessionCall,
  setPaymentPrepareDialog,
}) {
  const stripe = useStripe();
  const elements = useElements();
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (authorize.transactionInitData?.message === "Success") {
      async function handleStripe() {
        const { error } = await stripe.confirmPayment({
          elements,
          confirmParams: {
            // Make sure to change this to your payment completion page
            return_url: `${window.location.origin}/simulator1`,
          },
          redirect: "if_required",
        });
        dispatch(setLoader(false));
        if (isEmpty(error)) {
          makeSessionCall();
          setPaymentPrepareDialog(true);
        }
      }
      handleStripe();
    } else if (failureCheck(authorize.transactionInitData)) {
      errorToaster(paymentInitateMsgs(authorize.transactionInitData.status));
      dispatch(setLoader(false));
    }
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authorize.transactionInitData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    initiateTransaction(email);

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    setIsLoading(true);
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <LinkAuthenticationElement
        id="link-authentication-element"
        onChange={(event) => {
          setEmail(event.value.email);
        }}
      />
      <PaymentElement id="payment-element" />
      <button
        disabled={isLoading || !stripe || !elements}
        id="submit"
        style={{
          marginTop: "10px",
          padding: "10px",
          borderRadius: "5px",
          border: "none",
          letterSpacing: "0.00938em",
          background: "#333333",
          width: "100%",
          color: "#fff",
          cursor: "pointer",
          "&[disabled]": {
            opacity: "0.7",
            pointerEvents: "none",
            color: "#fff",
          },
          "&:hover": {
            background: "#333333",
            color: "#fff",
          },
        }}
      >
        <span id="button-text">CONNECT</span>
      </button>
    </form>
  );
}
