export const authConst = {
  clientId: "energy_maestro_lite",
  clientSecret: "Kf5Zi4DpAmecU9q12ggloklv8jje4eB4",
};

export const service = {
  CHARGE_SERVICE: "charge",
  USER_SERVICE: "user",
};

export const requests = {
  TOKEN_FAILED: "TOKEN_FAILED",
  ERR_NETWORK: "ERR_NETWORK",
};

export const httpMethods = {
  POST: "POST",
  GET: "GET",
  PUT: "PUT",
  DELETE: "DELETE",
  PATCH: "PATCH",
};

export const cacheConstants = {
  ERROR: "Something went wrong",
  SUCCESSMSG: "success",
  LAST_MODIFIED: "last-modified",
};

export const statusCodes = {
  successCode0: 0,
  successCode1: 200,
  successCode2: 201,
  unAuthorized: 401,
  badRequest: 400,
  permissionDenied: 403,
};

export const paths = ["/app-error", "/dashboard"];

export const chargingStates = {
  START: 0,
  STOP: 1,
};

export const sessionStatus = {
  CHARGE_INITIATE: "CHARGE_INITIATE",
  CHARGING_ONGOING: "CHARGING_ONGOING",
  CHARGING_STOPED: "CHARGING_STOPED",
  GUEST_PAYMENT_INITIATED: "GUEST_PAYMENT_INITIATED",
};

export const encryptConst = {
  secret: "sqQ39zYLK9JaElXyAPncSEKijH4oAJbjOHi539hKH6M=",
  iv_key: "Jecok68Y6IgV0XiJVNsyKA==",
};

export const paymentConst = {
  paypal: {
    // sunu.mukundan
    client_id: "ARSxxskY3GvUu8NBOf0P_6Lwi3Mpm-3Yqh_oVnVQKkh22NQXTNwMV2rOAkKuurBC9oNS2OUryXio_1bp",
    client_secret:
      "ELCotjPqzzHjgFn51AkX4eVmvLvpEmOcpPF2Nzgl3SpJXa7MUr48h6FK9bp7AJer7TCh7HVCzm4mZQEq",
  },
  stripe: {
    // publishable_key:
    //   "pk_test_51Q5Ki9Am43olN859iV8PzJZCjoQ2Mwc4X09VBg80XJzqTaTxuYhKDPg6BvLfSSlzb394yCsmKMsSb3pmS8EZaCdQ006VgTJJxW",
    publishable_key:
      "pk_live_51Q3hyjBttORbJ8NmjKNOdZCVXod43xIuUh1yh04i3sDXPvnYfM06viPMuLklyAbY800VJ4yx7l4ucbAkXnV8xxFx00y8JDy007",
  },
};

export const sessionApiStatus = {
  REMOTE_STOP_REQUESTED: "REMOTE_STOP_REQUESTED",
  CHARGING_STOPPED: "CHARGING_STOPPED",
  CHARGING_STARTED: "CHARGING_STARTED",
  REMOTE_START_REQUESTED: "REMOTE_START_REQUESTED",
};

export const paymentMethods = {
  PAYPAL: 0,
  PAYU: 1,
  RAZORPAY: 2,
  STRIPE: 3,
};
export const transactionMode = {
  WALLET: 0,
  PAYMENT_GATEWAY: 1,
};

export const errorCodes = {
  payment_initiate: [
    { 10506: "Active user not found" },
    { 10535: "user charging data not found" },
    {
      10543:
        "Invalid request parameter. Valid data required for transaction type, transaction mode and UserChargeSessionId.",
    },
    {
      10544:
        "Invalid request parameter. Valid data required for PaymentGatewayType, TermsAndConditions and PrivacyPolicy.",
    },
    { 10545: "Insufficient funds" },
    { 10546: "Transaction init failed from vendor." },
  ],
};

export const chargerStates = [0];

export const paymentModeTypes = { AUTOMATIC: "0", MANUAL: "1" };
